<template>
  <div>
    <slider-list></slider-list>
  </div>
</template>

<script>
import SliderList from '../../components/Slider/SliderList.vue';
export default {
  components: {
    SliderList,
  },
};
</script>

<template>
  <div class="container mt-5">
    <a-row>
      <a-col>
        <a-row type="flex" justify="space-between" class="mb-3 col-sm-block">
          <h4>Sliderlar ro'yxati</h4>
          <router-link :to="{ name: 'newSlider' }">
            <a-button size="large" type="primary">
              <a-icon type="file-add" />
              Slider qo'shish
            </a-button>
          </router-link>
        </a-row>
        <a-table
          :columns="columns"
          :data-source="data"
          bordered
          :scroll="{ x: 500 }"
        >
          <template slot="name" slot-scope="text, record">
            <router-link
              class="text-dark font-weight-bold"
              :to="{
                name: 'editSlider',
                params: {
                  sliderId: record.id,
                },
              }"
              ><a-icon type="edit" /> {{ text }}
            </router-link>
          </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const columns = [
  {
    title: 'Slider nomi',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
    responsive: ['xs'],
  },
  {
    title: 'Kategoriyasi',
    className: 'column-money',
    dataIndex: 'category',
    responsive: ['sm'],
  },
  {
    title: 'Content',
    dataIndex: 'content',
  },
];
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      columns: columns,
      data: [],
    };
  },
  methods: {
    ...mapActions({
      getAllSlider: 'slider/getAllSlider',
    }),
  },

  created() {
    this.getAllSlider()
      .then(({ results }) => {
        this.data = results.map((item) => {
          return {
            key: item.id,
            id: item.id,
            category: item.category.name_ru,
            name: item.title,
            content: item.content,
          };
        });
      })
      .catch((error) => {
        this.$notification['error']({
          message: 'Xatolik',
          description: 'Serverda Xatolik Boldi ):' + error.message,
        });
        setTimeout(() => {
          this.$router.push('/product');
        }, 1000);
      });
  },
};
</script>
